import * as React from "react";

import Layout from "@layouts/Layout";
import Widgets from "@components/Widgets";
import Seo from "@components/Seo";
import MessageForm from "@components/MessageForm";
import Contacts from "@components/Contacts";
import { Typography } from "@components/atoms";

const ContactPage: React.FC = () => {
  return (
    <>
      <Widgets>
        <Seo title="Contact Us" />
        <Typography variant="h1">Get in Touch</Typography>
        <Contacts />
        <Typography variant="h3">Send Message</Typography>
        <MessageForm />
      </Widgets>
    </>
  );
};

export default ContactPage;
