import styled from "styled-components";

type FormProps = {
  netlify?: boolean;
};

export const Container = styled.form<FormProps>`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }

  input,
  textarea {
    background-color: ${(props) => props.theme.pallette.background.paper};
    box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  & > *:not(:last-child) {
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;
