import * as React from "react";

import { Input, TextArea, Button } from "@components/atoms";

import { Container, Row } from "./MessageForm.styles";

export interface MessageFormProps {}

const MessageForm: React.FC<MessageFormProps> = () => {
  return (
    <Container
      method="POST"
      name="contact"
      data-netlify="true"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <Row>
        <Input id="name" placeholder="Name" />
        <Input id="mail" placeholder="Email address" />
      </Row>
      <Input id="subject" placeholder="Subject" />
      <TextArea id="message" placeholder="Your message here..." />
      <Button type="submit">Submit Message</Button>
    </Container>
  );
};

export default MessageForm;
