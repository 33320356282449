import styled from "styled-components";

export const Container = styled.ul`
  list-style: none;
  margin: 40px 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & > li {
    flex: 1;

    &:not(:last-child) {
      margin-bottom: 32px;

      @media (min-width: 1024px) {
        margin-bottom: 0;
        margin-right: 32px;
      }
    }
  }
`;

export const Contact = styled.li`
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: ${(props) => props.theme.pallette.background.paper};
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  border-radius: ${(props) => props.theme.utils.borderRadius};

  & > div:nth-child(1) {
    margin-right: 16px;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    border-radius: 10px;

    svg {
      fill: ${(props) => props.theme.pallette.primary.main};
    }
  }

  & > div:nth-child(2) {
    h3,
    p {
      margin: 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: ${(props) => props.theme.pallette.text.default};
    }

    p {
      color: ${(props) => props.theme.pallette.text.default};
      font-weight: 300;
      font-size: 12px;
    }
  }
`;
