import * as React from "react";

import { Icon } from "@components/atoms";

import contacts from "@constants/contacts";

import { Container, Contact } from "./Contacts.styles";

export interface ContactsProps {}

const Contacts: React.FC<ContactsProps> = () => {
  return (
    <Container>
      {contacts.map((item) => (
        <Contact key={item.type}>
          <div>
            <Icon name={item.icon} size={25} />
          </div>
          <div>
            <h3>{item.type}</h3>
            <p>{item.value}</p>
          </div>
        </Contact>
      ))}
    </Container>
  );
};

export default Contacts;
